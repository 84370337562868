// frontend/src/components/Layout/Navbar.js

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode'; // Fixed import for jwtDecode
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Button,
  TextField,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import './Navbar.css';

function Navbar() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false); // State for mobile drawer
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if screen is mobile

  let user = null;
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      user = decodedToken.name || decodedToken.username || decodedToken.user;
    } catch (error) {
      console.error('Invalid token:', error);
      localStorage.removeItem('token');
      navigate('/login');
    }
  }

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  const handleMenuClick = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  const toggleDrawer = (open) => () => setDrawerOpen(open);

  return (
    <AppBar position="static" sx={{ backgroundColor: 'black', color: 'white' }} elevation={1}>
      <Toolbar sx={{ minHeight: 48, paddingX: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 2 }}>
          <Link to="/">
            <img src="/lcm-logo.png" alt="LCM Logo" className="logo" />
          </Link>
        </Box>

        {!isMobile && (
          <>
            <Button component={Link} to="/categories" sx={{ color: 'white', marginRight: 4, textTransform: 'none' }}>
              Categories
            </Button>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search for courses"
              sx={{
                flexGrow: 1,
                maxWidth: '500px',
                backgroundColor: 'white',
                borderRadius: '4px',
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </>
        )}

        {/* Mobile Menu Icon */}
        {isMobile ? (
          <IconButton color="inherit" onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 2 }}>
            <Button onClick={handleMenuClick} endIcon={<ArrowDropDownIcon />} sx={{ color: 'white', textTransform: 'none', marginRight: 2 }}>
              Teach
            </Button>

            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
              <MenuItem onClick={() => { handleMenuClose(); navigate('/pricing'); }}>Pricing</MenuItem>
              <MenuItem onClick={() => { handleMenuClose(); navigate('/create-course'); }}>Create Course</MenuItem>
              <MenuItem onClick={() => { handleMenuClose(); navigate('/my-teachings'); }}>My Teachings</MenuItem>
            </Menu>

            <Button component={Link} to="/my-learnings" sx={{ color: 'white', textTransform: 'none', marginRight: 2 }}>
              My Learnings
            </Button>
            <Button component={Link} to="/referral-discount" sx={{ color: 'white', textTransform: 'none', marginRight: 2 }}>
              Referral Discount
            </Button>

            <IconButton color="inherit">
              <ShoppingCartIcon />
            </IconButton>

            {user ? (
              <>
                <Typography sx={{ marginLeft: 2, textTransform: 'none' }}>Hello, {user}</Typography>
                <Button color="inherit" onClick={handleLogout} sx={{ marginLeft: 2, textTransform: 'none' }}>
                  Logout
                </Button>
              </>
            ) : (
              <>
                <Button component={Link} to="/login" sx={{ color: 'white', textTransform: 'none' }}>Login</Button>
                <Button component={Link} to="/register" variant="outlined" sx={{ marginLeft: 2, color: 'white', borderColor: 'white', textTransform: 'none' }}>Sign Up</Button>
              </>
            )}
          </Box>
        )}

        {/* Mobile Drawer */}
        <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
          <Box role="presentation" sx={{ width: 250 }} onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
            <List>
              <ListItem button component={Link} to="/categories">
                <ListItemText primary="Categories" />
              </ListItem>
              <ListItem>
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Search for courses"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </ListItem>
              <ListItem button component={Link} to="/pricing">
                <ListItemText primary="Pricing" />
              </ListItem>
              <ListItem button component={Link} to="/create-course">
                <ListItemText primary="Create Course" />
              </ListItem>
              <ListItem button component={Link} to="/my-teachings">
                <ListItemText primary="My Teachings" />
              </ListItem>
              <ListItem button component={Link} to="/my-learnings">
                <ListItemText primary="My Learnings" />
              </ListItem>
              <ListItem button component={Link} to="/referral-discount">
                <ListItemText primary="Referral Discount" />
              </ListItem>
              {user ? (
                <>
                  <ListItem>
                    <Typography>Hello, {user}</Typography>
                  </ListItem>
                  <ListItem button onClick={handleLogout}>
                    <ListItemText primary="Logout" />
                  </ListItem>
                </>
              ) : (
                <>
                  <ListItem button component={Link} to="/login">
                    <ListItemText primary="Login" />
                  </ListItem>
                  <ListItem button component={Link} to="/register">
                    <ListItemText primary="Sign Up" />
                  </ListItem>
                </>
              )}
            </List>
          </Box>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
