// src/components/Layout/Loading.js
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

function Loading() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '60vh' }}>
      <CircularProgress 
        size={60} 
        thickness={5} 
        style={{ color: '#018085' }} // Apply custom theme color
      />
    </div>
  );
}

export default Loading;
